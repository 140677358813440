<template>
  <input 
    ref="inputRef" 
    :value="currencyValue"
  >
</template>

<script>
import { watch, computed } from 'vue'
import useCurrencyInput from 'vue-currency-input'

export default {
  name: 'CurrencyInput',
  props: ['modelValue', 'symbol'],
  setup (props) {
    const {
      inputRef,
      formattedValue,
      setValue
    } = useCurrencyInput({
      currency: 'GTQ',
      locale: 'es-GT',
      currencyDisplay: 'hidden',
      distractionFree: {
        hideCurrencySymbol: true,
        hideNegligibleDecimalDigits: true,
        hideGroupingSymbol: false
      },
      useGrouping: true,
      precision: { min: 0, max: 2 }
    })

    const currencyValue = computed(() => {
      if (!formattedValue.value) {
        return 0
      }

      if (parseFloat((formattedValue.value).replace(/[^0-9.-]+/g,"")) == 0) {
        return 0
      }
      var dd = (formattedValue.value || '0').toString()
      var symbol = typeof props.symbol == 'undefined' ? 'Q' : (props.symbol || '')
      return dd.replace('$', symbol)
    })

    watch(() => props.modelValue, (value) => {
      setValue(value)
    })

    /*watch(() => props.options, (options) => {
      setOptions(options)
    })*/

    return { inputRef, formattedValue, currencyValue }
  }
}
</script>